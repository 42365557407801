<template>
  <div class="outer-page">
    <div class="center-width">
      <div class="scroll-component">
        <div class="sub-company-list" ref="subBox">
          <div
            :style="{
              transform: `translateX(${translateNum}px)`,
              transition: startRecord ? '' : 'all 0.2s linear',
            }"
            class="sub-company-list-inner"
          >
            <template v-for="(company, index) in subCompanies">
              <div
                :class="[companyIndex == index ? 'current-company' : '']"
                @click="() => companyClick(company, index)"
                :key="company.id"
                ref="currentCompany"
              >
                {{ company.name }}
              </div>
            </template>
          </div>
        </div>
        <div
          @click="turnLeft"
          :class="[
            'turn-left',
            translateNum === 0 ? 'end-left-icon' : 'left-icon',
          ]"
        ></div>
        <div
          @click="turnRight"
          :class="[
            'turn-right',
            translateNum <= maxTranslate ? 'end-right-icon' : 'right-icon',
          ]"
        ></div>
      </div>
      <div class="content">
        <div class="list-one">
          <ul>
            <li
              v-for="(item, index) in hnews"
              :key="index"
              @click="checkHonorDetail(item)"
            >
              <a>
                <img :src="servicePath + item.cover" alt="" />
              </a>
            </li>
          </ul>
          <div class="clears"></div>
        </div>
        <div class="list-two">
          <ul>
            <li
              v-for="(item, index) in news"
              :key="index"
              @click="checkHonorDetail(item)"
            >
              <a>
                <img :src="servicePath + item.cover" alt="" />
              </a>
            </li>
          </ul>
          <div class="clears"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      listOne: [],
      listTwo: [],

      subCompanies: [],
      companyIndex: 0,
      news: [],
      hnews: [],
      servicePath: "",
      showInfos: [],

      translateNum: 0,
      startRecord: false,
      maxTranslate: 0,
      showPaneWidth: 0,
      totalWidth: 0,
      n: 0,
    };
  },
  computed: {
    //获取菜单
    getSubMenu() {
      return this.$store.state.permission.subMenu;
    },
    //获取当前的router
    getCurrentRoute() {
      return this.$store.state.permission.currentRoute;
    },
    // maxTranslate() {
    //   return (
    //     this.showPaneWidth -
    //     this.subCompanies.length *
    //       this.$refs.currentCompany[this.compantIndex].offsetWidth
    //   );
    // },
  },
  methods: {
    getHonors() {
      this.$post("websitnews/grouplist", {
        type: 1,
        keyword: "honoraryCertificate",
      }).then((res) => {
        this.listOne = res;
      });
    },
    getHonors2() {
      this.$post("websitnews/grouplist", {
        type: 2,
        keyword: "honoraryCertificate",
      }).then((res) => {
        this.listTwo = res;
      });
    },
    checkHonorDetail(item) {
      this.$router.push({
        name: "mnewsInfoTwo",
        query: {
          id: item.id,
          key: this.$route.name,
          companyId: this.subCompanies[this.companyIndex].id,
        },
      });
    },
    getDatas() {
      if (Object.keys(this.getSubMenu).length == 0 || this.$route.params.id) return;
      let child = this.getSubMenu.childList.filter(
        (item) => item.router == this.getCurrentRoute
      )[0];
      if (child.Interface) {
        this.$post(child.Interface).then((res) => {
          this.subCompanies = res;
          this.companyClick(res[0], 0);
          this.totalWidth = 0;
          this.$nextTick(() => {
            this.$refs.currentCompany.forEach((item) => {
              this.totalWidth += item.clientWidth + 20;
            });
            this.maxTranslate = this.showPaneWidth - this.totalWidth;
          });
        });
      }
    },
    companyClick(company, index) {
      this.companyIndex = index;
      this.showInfos = company.news;
      this.news = company.news.filter((item) => item.type == 1);
      this.hnews = company.news.filter((item) => item.type == 2);
      this.servicePath = company.servicePath;
    },
    turnLeft() {
      let next =
        this.translateNum + this.$refs.currentCompany[this.n].offsetWidth + 20;
      if (next > 0) {
        this.translateNum = 0;
        return;
      }
      this.n--;
      this.translateNum = next;
    },
    turnRight() {
      let next =
        this.translateNum - this.$refs.currentCompany[this.n].offsetWidth - 20;
      let flag = this.showPaneWidth - next >= this.totalWidth;
      if (flag) {
        this.translateNum = this.showPaneWidth - this.totalWidth;
        return;
      }
      this.n++;
      this.translateNum = next;
    },
  },
  mounted() {
    // this.getHonors();
    // this.getHonors2();
    // let vConsole = new VConsole();
    if (this.$route.params.id) {
      this.$router.push({
        name: "mnewsInfoTwo",
        query: {
          id: this.$route.params.id,
          key: this.$route.name,
          companyId: this.$route.params.catId,
        },
      });
      return
    }
    this.getDatas();
    this.$nextTick(() => {
      this.showPaneWidth = this.$refs.subBox.clientWidth;
    });
  },
  watch: {
    subMenu: {
      immediate: true,
      handler(n) {
        this.getDatas();
      },
    },
    $route() {
      this.getDatas();
    },
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  padding: 0.5rem 0;
  font-size: 0.577rem;
  line-height: 1rem;
  .center-width {
    width: 94%;
    margin: 0 3%;
    .scroll-component {
      padding: 0 1.5rem;
      position: relative;
      width: 100%;
      margin-bottom: 36px;

      // border-bottom: 1px solid #ececec;
      &::after {
        content: "";
        display: block;
        height: 1px;
        position: absolute;
        width: 100%;
        bottom: 0px;
        left: 0;
        background-color: #ececec;
        z-index: 2;
      }

      .turn-left {
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 28px;
        width: 16px;
        height: 16px;
        background-size: cover;
      }

      .end-left-icon {
        background-image: url(/imgs/c-left11.png);
      }

      .left-icon {
        background-image: url(/imgs/c-left01.png);
      }

      .turn-right {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 28px;
        width: 16px;
        height: 16px;
      }

      .end-right-icon {
        background-image: url(/imgs/c-right11.png);
      }

      .right-icon {
        background-image: url(/imgs/c-right01.png);
      }

      .sub-company-list {
        width: 100%;
        overflow: hidden;
        position: relative;
        height: 70px;
        z-index: 3;

        .sub-company-list-inner {
          display: flex;
          position: absolute;

          div {
            flex-grow: 0;
            flex-shrink: 0;
            // width: 160px;
            margin-right: 20px;
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 3;
            cursor: pointer;

            &:hover {
              color: #ff9c00;

              &::after {
                content: "";
                display: block;
                height: 1px;
                position: absolute;
                width: 100%;
                bottom: 0px;
                left: 0;
                background-color: #ff9c00;
                z-index: 3;
              }
            }
          }

          .current-company {
            color: #ff9c00;

            &::after {
              content: "";
              display: block;
              height: 1px;
              position: absolute;
              width: 100%;
              bottom: 0px;
              left: 0;
              background-color: #ff9c00;
              z-index: 3;
            }
          }
        }
      }
    }
    .content {
      font-size: 0.577rem;
      line-height: 0.88rem;
      color: #666666;
      //   padding-top: 0.5rem;
      .list-one {
        ul {
          // height: 5.3rem;
          list-style: none;
          overflow: hidden;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          li {
            // float: left;
            width: 48%;
            height: 5rem;
            margin-bottom: 0.5rem;
            a {
              img {
                display: block;
                height: 5rem;
                width: 100%;
              }
            }
          }
        }
      }
      .list-two {
        ul {
          // height: 6.9rem;
          list-style: none;
          overflow: hidden;
          display: flex;
          // justify-content: space-between;
          flex-wrap: wrap;
          li {
            // float: left;
            width: 32%;
            height: 6.66rem;
            margin-bottom: 0.5rem;
            margin-right: 1.3%;
            a {
              img {
                display: block;
                height: 6.66rem;
                width: 100%;
              }
            }
          }
        }
      }
      .clears {
        clear: both;
        line-height: 0;
        overflow: hidden;
        font-size: 0;
        height: 0;
      }
    }
  }
}
</style>