var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"outer-page"},[_c('div',{staticClass:"center-width"},[_c('div',{staticClass:"scroll-component"},[_c('div',{ref:"subBox",staticClass:"sub-company-list"},[_c('div',{staticClass:"sub-company-list-inner",style:({
            transform: ("translateX(" + _vm.translateNum + "px)"),
            transition: _vm.startRecord ? '' : 'all 0.2s linear',
          })},[_vm._l((_vm.subCompanies),function(company,index){return [_c('div',{key:company.id,ref:"currentCompany",refInFor:true,class:[_vm.companyIndex == index ? 'current-company' : ''],on:{"click":function () { return _vm.companyClick(company, index); }}},[_vm._v(" "+_vm._s(company.name)+" ")])]})],2)]),_c('div',{class:[
          'turn-left',
          _vm.translateNum === 0 ? 'end-left-icon' : 'left-icon' ],on:{"click":_vm.turnLeft}}),_c('div',{class:[
          'turn-right',
          _vm.translateNum <= _vm.maxTranslate ? 'end-right-icon' : 'right-icon' ],on:{"click":_vm.turnRight}})]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"list-one"},[_c('ul',_vm._l((_vm.hnews),function(item,index){return _c('li',{key:index,on:{"click":function($event){return _vm.checkHonorDetail(item)}}},[_c('a',[_c('img',{attrs:{"src":_vm.servicePath + item.cover,"alt":""}})])])}),0),_c('div',{staticClass:"clears"})]),_c('div',{staticClass:"list-two"},[_c('ul',_vm._l((_vm.news),function(item,index){return _c('li',{key:index,on:{"click":function($event){return _vm.checkHonorDetail(item)}}},[_c('a',[_c('img',{attrs:{"src":_vm.servicePath + item.cover,"alt":""}})])])}),0),_c('div',{staticClass:"clears"})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }